import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "react-bootstrap"

const TermsPage = () => (
  <Layout>
        <SEO title="About" />
        <Container className="pad_header">
            <h1 className="header_title txt_align_center ptb_35">Jus-Pharma Terms of Use</h1>
            <div className="txt_section">
                <p><b>IMPORTANT NOTICE: THESE TERMS INCLUDE AN AGREEMENT TO
                    MANDATORY ARBITRATION, WHICH MEANS THAT YOU AGREE TO SUBMIT
                    ANY DISPUTE RELATED TO YOUR USE OF THE PLATFORM TO BINDING
                    INDIVIDUAL ARBITRATION RATHER THAN PROCEED IN COURT. THESE TERMS
                    ALSO INCLUDE A JURY WAIVER AND A CLASS ACTION WAIVER, THE LATTER
                    OF WHICH MEANS THAT YOU AGREE TO PROCEED WITH ANY DISPUTE
                    INDIVIDUALLY AND NOT AS PART OF A CLASS ACTION.</b></p>
                <p>NOTE THAT THESE TERMS APPLY ONLY TO THE JUS-PHARMA APPS AND WEBSITE;
                    OTHER THIRD PARTY APPLICATIONS OR SERVICES PROVIDED WITHIN OR THROUGH
                    THE PLATFORM MAY HAVE SEPARATE TERMS AND CONDITIONS AND PRIVACY
                    POLICIES THAT APPLY TO THE USE OF THOSE APPLICATIONS OR SERVICES</p>
                <p>These Jus-Pharma Terms of Use (the <b>“Terms”</b>) set forth the legally binding terms that govern your
                    access to and use of the <a href="https://jusglobal.com/jus-pharma">https://jusglobal.com/jus-pharma</a> website (the <b>“Website”</b>) and the JusPharma applications (each, an <b>“App”</b>). The Website and the Platforms are collectively referred to
                    in these Terms as the <b>“Platform</b>.” The Platform is made available by JusGlobal LLC d/b/a JusPharma (<b>“Jus-Pharma”</b>, <b>“we”</b>, <b>“our”</b>, <b>“us”</b>), and its designated service providers acting on
                    behalf of Jus-Pharma or its affiliated companies (each, a <b>“Jus-Pharma Service Provider”</b>), and
                    any Content (as defined in Section 1 below) is owned by Jus-Pharma or its affiliated companies,
                    or licensed by Jus-Pharma from third parties.</p>
                <p>Please read these Terms carefully before using the Platform as these Terms constitute a legally
                    binding agreement between you and Jus-Pharma. <b>By clicking “I Accept” or a similar
                    designation, establishing an Account (as defined in Section 2 below), or downloading,
                    installing, accessing and using the Platform, you (a) accept and agree to be bound by and to
                    comply with these Terms and (b) confirm you have read, understood and consent to the
                    collection, use, disclosure, and other handling of your information as described in our
                    Privacy Policy as may be updated from time to time in accordance with applicable laws and
                    regulations.</b> If you do not accept or understand these Terms, please do not download, access, use,
                    or register with the Platform.</p>
                <p>We may terminate these Terms for any or no reason, and with or without notice to you. However,
                    the portions dealing with intellectual property rights, indemnification, disclaimers, limitation of
                    liability, governing law, and any other portions which by their nature would continue beyond the
                    termination of these Terms, will survive the termination of these Terms. Your rights under these
                    Terms will terminate automatically if you fail to comply with any provision of these Terms. Upon
                    termination of these Terms, you shall cease all use of the Platform.</p>
            </div>
            
            <div className="txt_section">
                <h3 className="sub_title">1. OWNERSHIP AND LICENSE</h3>
                <p>The Platform contains visual interfaces, graphics, designs, trademarks, images, information, data,
                    computer code, software, services, materials, and other content (collectively, <b>“Content”</b>), all of 
                    which is protected under intellectual property and proprietary rights and other applicable federal,
                    foreign, international, provincial, local and state laws. Content may be in the form of text, data,
                    sound, graphics, images, software or other forms now known or later invented.</p>
                <p>Subject to your complete and ongoing compliance with all terms and conditions set forth in these
                    Terms, Jus-Pharma grants you (a) a limited, non-exclusive, non-transferable, non-sublicensable,
                    revocable right and license to use the Platform, including via Apps downloaded directly from a
                    legitimate marketplace (such as Apple’s iTunes store or Google Play), solely in object code format
                    and solely for your own personal non-commercial use for lawful purposes, and (b) permission to
                    access and use the Platform, only for your own personal non-commercial use, through the use of
                    a licensed copy of the Platform. Jus-Pharma may, in its sole discretion, suspend, discontinue or
                    terminate this license to you at any time for any reason.</p>
                <p>Jus-Pharma reserves the right at any time and from time to time to modify, suspend, discontinue,
                    or cancel any or all of the Platform’s operation, or portions thereof, with or without notice to you.
                    You acknowledge and agree that Jus-Pharma will not be liable to you or any third party for any
                    such modification, suspension, discontinuation, or cancellation of any or all of the Platform’s
                    operation.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">2. ACCOUNT REGISTRATION; PRIVACY</h3>
                <p>You may be required to register for an account on the Platform (<b>“Account”</b>) in order to access and
                    use the features of the Platform. To register for an Account and to use certain features of the
                    Platform, you may be required to provide your personally identifiable information, such as your
                    name, address, phone number, and email address and, in certain instances via provision directly to
                    an independent third party payment processor, your payment card information. Before further
                    using the Platform, please review our Privacy Policy for an explanation of how we collect, use and
                    protect your personally identifiable information. You agree to provide true, accurate and complete
                    information about yourself as prompted by the online or mobile forms. If we have reasonable
                    grounds (as determined by us in our sole discretion) to suspect that the personally identifiable
                    information you provide for registration or other purposes is untrue, inaccurate or incomplete, we
                    have the right to deny you access to the Platform, with or without notice, and to suspend or
                    terminate your Account.</p>
                <p>We will have no liability in connection with your failure to maintain accurate personally
                    identifiable information in connection with your Account, including, but not limited to, your
                    failure to receive critical information from Jus-Pharma or the Platform.</p>
                <p>Your selection and use of an Account username and password, and any other information required
                    for access and authentication purposes, are subject to these Terms. You agree not to share your
                    username, password and any security-related information with anyone. You are responsible for all
                    uses of your Account and for ensuring that access to and use of your Account complies fully with
                    these Terms. You acknowledge and agree that any use of your Account through your username,
                    password and security information shall be deemed to be your actions that we may rely upon. You
                    are solely responsible for protecting the confidentiality and security of your Account.</p>
            </div>
            <div className="txt_section">
                <h3 className="sub_title">3. RESTRICTIONS ON ACCESSING AND USING THE PLATFORM</h3>
                <p>As a condition to accessing and using the Platform in accordance with the license granted to you
                    under these Terms, you agree to comply with all applicable laws, rules, regulations and other legal
                    requirements relating to your access to and use of the Platform. Further, you agree not to:</p>
                <ul>
                    <li><p>Use the Platform for any illegal purpose, or in violation of any local, state, national, or
                        international law;</p></li>
                    <li><p>Violate, or encourage others to violate, the rights of third parties, including by infringing
                        or misappropriating third party intellectual property rights;</p></li>
                    <li><p>Interfere with security-related features of the Platform, including without limitation by (i)
                        disabling or circumventing features that prevent or limit use or copying of any Content, or
                        (ii) reverse engineering or otherwise attempting to discover the source code of the Platform
                        or any part thereof except to the extent that such activity is expressly permitted by
                        applicable law;</p></li>
                    <li><p>Interfere with the operation of the Platform or any user’s enjoyment of the Platform,
                        including, without limitation, by (i) disseminating viruses, adware, spyware, worms, or
                        other malicious code; (ii) making unsolicited offers or advertisements to other users of the
                        Platform; (iii) attempting to collect personal information about users or third parties
                        without their consent; or (iv) interfering with or disrupting any networks, equipment, or
                        servers connected to or used to provide the Platform, or violating the regulations, policies,
                        or procedures of such networks, equipment, or servers;</p></li>
                    <li><p>Perform any fraudulent activity including impersonating any person or entity, claiming
                        false affiliations, or accessing the Platform accounts of others without permission;</p></li>
                    <li><p>Sell or otherwise transfer the access granted herein or any Content or any right or ability
                        to view, access, or use any Content; or</p></li>
                    <li><p>Attempt to do any of the foregoing, or assist or permit any persons in engaging in any of
                        the activities described in this Section</p></li>
                </ul>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">4. THIRD PARTY SERVICES</h3>
                <p>The Platform may contain links to other sites and services not owned or operated by Jus-Pharma.
                    Jus-Pharma has no control over these sites and services, and you acknowledge and agree that (a)
                    Jus-Pharma is not responsible for the availability of any such sites and services, and (b) Jus-Pharma
                    does not endorse and is not responsible or liable for any such sites and services, or for any damage
                    or loss caused or alleged to be caused by or in connection with your use thereof. You should refer
                    to information provided directly by those sites and services to learn about their terms of use,
                    privacy policies, and data collection/distribution procedures.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">5. FEEDBACK</h3>
                <p>Jus-Pharma may use and disclose any comments, suggestions, bug reports or feedback relating to
                the Platform (collectively, <b>“Feedback”</b>) without any restriction. Without limitation, Jus-Pharma
                will be entitled to use the Feedback for any commercial or other purpose whatsoever, without
                compensation to you or any other person sending the Feedback.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">6. INDEMNIFICATION</h3>
                <p>You agree to defend, indemnify and hold harmless Jus-Pharma and its parent, subsidiaries, and
                    affiliated companies, and their respective service providers, agencies, licensors, and contractors
                    (including the Jus-Pharma Service Providers), together with each of their respective employees,
                    agents, directors, officers and shareholders (all of the foregoing, collectively, the “Jus-Pharma
                    Entities”), from and against all liabilities, claims, damages and expenses (including reasonable
                    attorneys’ fees and costs) arising out of your use of the Platform or your breach or alleged breach
                    of any of these Terms.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">For Participants in Clinical Trials and Research Studies</h3>
                <p>We process information on behalf of pharmaceutical companies and medical device manufacturers,
                    and we have processes in place to help them respond to requests from individuals to exercise data
                    protection rights that apply under applicable law. Please reach out to your contact at the applicable
                    company directly with questions about how you can exercise your data protection rights as a
                    participant.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">7. DISCLAIMERS; NO WARRANTIES</h3>
                <p>You understand and agree that your use of the Platform and any services of the Platform is solely
                    at your own risk and that you will be solely responsible for any damage to your mobile device or
                    computer or any other equipment or loss of data that may result from your use of the Platform.</p>
                <p>The Platform and Content are provided on an “AS IS” and “AS AVAILABLE” basis without any
                    warranty of any kind, expressed, implied or statutory. WE AND THE JUS-PHARMA SERVICE
                    PROVIDERS SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF
                    MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, AND NONINFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING,
                    USAGE OR TRADE. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
                    OBTAINED BY YOU FROM US OR THROUGH THE PLATFORM SHALL CREATE ANY
                    WARRANTY NOT EXPRESSLY MADE HEREIN. YOU MAY NOT RELY ON ANY SUCH
                    INFORMATION OR ADVICE. TO THE EXTENT JURISDICTIONS DO NOT ALLOW THE
                    EXCLUSION OF CERTAIN WARRANTIES, SOME OF THE ABOVE EXCLUSIONS MAY
                    NOT APPLY TO YOU.</p>
                <p>JUS-PHARMA AND THE JUS-PHARMA SERVICE PROVIDERS MAKE NO WARRANTIES
                    THAT THE PLATFORM OR ANY CONTENT OR SERVICES OBTAINED THROUGH THE
                    PLATFORM WILL MEET YOUR REQUIREMENTS, OR THAT THE PLATFORM WILL BE
                    UNINTERRUPTED, TIMELY, SECURE, NON-INFRINGING OR ERROR-FREE. YOU
                    UNDERSTAND AND AGREE THAT YOU ARE RESPONSIBLE FOR ANY AND ALL
                    CHARGES, COSTS AND EXPENSES FOR ACCESS TO OR USE OF THE PLATFORM. WE
                    ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY PERFORMANCE
                    DEGRADATION, INTERRUPTION OR DELAYS OF THE PLATFORM, OR ERRORS OR
                    OMISSIONS IN ANY CONTENT ON OR THROUGH THE PLATFORM.</p>
                <p>ANY DECISION OR ACTION TAKEN BY YOU ON THE BASIS OF INFORMATION,
                    CONTENT, OR SERVICES PROVIDED IN OR THROUGH THE PLATFORM IS AT YOUR
                    SOLE DISCRETION AND RISK. WE AND THE JUS-PHARMA SERVICE PROVIDERS ARE 
                    NOT RESPONSIBLE OR LIABLE FOR ANY SUCH DECISION, OR FOR THE ACCURACY,
                    COMPLETENESS, USEFULNESS, OR AVAILABILITY OF ANY CONTENT DISPLAYED,
                    TRANSMITTED OR OTHERWISE MADE AVAILABLE IN THE PLATFORM.</p>
            </div>
            
            <div className="txt_section">
                <h3 className="sub_title">8. LIMITATION OF LIABILITY</h3>
                <p>YOU AGREE THAT THE ENTIRE LIABILITY OF ANY JUS-PHARMA ENTITY TO YOU,
                    AND THAT YOUR EXCLUSIVE REMEDY, IN LAW, IN EQUITY, OR OTHERWISE, WITH
                    RESPECT TO ANY DAMAGES SUFFERED BY YOU THROUGH USE OF THE PLATFORM
                    IS LIMITED TO THE AMOUNT OF TEN DOLLARS ($10.00). NO JUS-PHARMA ENTITY
                    SHALL BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL
                    OR PUNITIVE DAMAGES EVEN IF A JUS-PHARMA ENTITY HAS BEEN ADVISED OF
                    THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT THAT A STATE OR
                    JURISDICTION DOES NOT PERMIT THE EXCLUSION OR LIMITATION OF LIABILITY
                    AS SET FORTH IN THIS PARAGRAPH, THE JUS-PHARMA ENTITIES’ LIABILITY IS
                    LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW IN SUCH STATES OR
                    JURISDICTIONS.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">9. DISPUTE RESOLUTION AND ARBITRATION</h3>
                <p>PLEASE READ THIS PROVISION CAREFULLY. IT INCLUDES AN AGREEMENT TO
                    MANDATORY ARBITRATION, WHICH MEANS THAT YOU AGREE TO SUBMIT ANY
                    DISPUTE RELATED TO THESE TERMS AND YOUR USE OF THE PLATFORM TO
                    BINDING INDIVIDUAL ARBITRATION RATHER THAN PROCEED IN COURT. THIS
                    PROVISION ALSO INCLUDES A CLASS ACTION WAIVER, WHICH MEANS THAT YOU
                    AGREE TO PROCEED WITH ANY DISPUTE INDIVIDUALLY AND NOT AS PART OF A
                    CLASS ACTION. THIS PROVISION ALSO INCLUDES A WAIVER OF JURY TRIAL.</p>
                <p>There may be instances when you have a problem or dispute with respect to the Platform that needs
                    special attention. In those instances, Jus-Pharma is committed to working with you to reach a
                    reasonable resolution that satisfies you; however, we can only do this if we know about and
                    understand your issue. Therefore, for any problem or dispute that you may have with Jus-Pharma
                    relating to these Terms and your use of the Platform, you acknowledge and agree that you will
                    first give Jus-Pharma an opportunity to resolve your problem or dispute. This includes you first
                    sending a written description of your problem or dispute by e-mail to <a href="mailto:info@Jus-Pharma.com">info@Jus-Pharma.com</a>. You
                    then agree to negotiate with Jus-Pharma in good faith about your problem or dispute relating to
                    these Terms or your use of the Platform. This should lead to resolution, but if for some reason your
                    problem or dispute relating to these Terms or your use of the Platform is not resolved satisfactorily
                    within sixty (60) days after Jus-Pharma’s receipt of your written description of it, you agree to the
                    further dispute resolution provisions below.</p>
                <p>You agree that the sole and exclusive forum and remedy for any disputes and claims that cannot
                    be resolved informally and that arise out of your use of the Platform and these Terms shall be final
                    and binding arbitration, except to the extent that you have in any manner infringed upon or violated
                    or threatened to infringe upon or violate any of the Jus-Pharma Entities’ or any third party patent,
                    copyright, trademark, trade secret, privacy or publicity rights, in which case you acknowledge that
                    the Jus-Pharma Entities may seek injunctive or other appropriate relief in court.</p>
                <p>Arbitration under these Terms shall be conducted by the American Arbitration Association
                    (<b>“AAA”</b>). The arbitration shall be administered by AAA pursuant to its Consumer Arbitration
                    Rules. If the arbitration results in an award, then judgment on the award may be entered in any
                    court having jurisdiction. This clause shall not preclude parties from seeking provisional remedies
                    in aid of arbitration from a court of appropriate jurisdiction. You and we agree to pay our own
                    fees, costs, and expenses, including those for any attorneys, experts and witnesses. You agree that
                    any claim for, or award of attorneys’ fees is waived. An arbitrator may award on an individual
                    basis any relief. As a limited exception to the agreement to arbitrate, you and we agree that you
                    may take claims to small claims court, if your claims qualify for hearing by such court.</p>
                <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NO ARBITRATION OR
                    OTHER CLAIM UNDER THESE TERMS SHALL BE JOINED TO ANY OTHER
                    ARBITRATION OR CLAIM, INCLUDING ANY ARBITRATION OR CLAIM INVOLVING
                    ANY OTHER CURRENT OR FORMER USER OF THE PLATFORM, AND NO CLASS
                    ARBITRATION PROCEEDINGS SHALL BE PERMITTED. IN THE EVENT THAT THIS
                    CLASS ACTION WAIVER IS DEEMED UNENFORCEABLE, THEN ANY PUTATIVE
                    CLASS ACTION MAY ONLY PROCEED IN A COURT OF COMPETENT JURISDICTION
                    AND NOT IN ARBITRATION.</p>
                <p>WE BOTH AGREE THAT, WHETHER ANY CLAIM IS IN ARBITRATION OR IN COURT,
                    YOU AND JUS-PHARMA BOTH WAIVE ANY RIGHT TO A JURY TRIAL INVOLVING
                    ANY CLAIMS OR DISPUTES BETWEEN US. IN NO EVENT SHALL ANY CLAIM, ACTION
                    OR PROCEEDING BY YOU RELATED IN ANY WAY TO THESE TERMS BE INSTITUTED
                    MORE THAN TWO (2) YEARS AFTER THE CAUSE OF ACTION ACCRUES UNDER
                    APPLICABLE LAW.</p>
            </div>

            <div className="txt_section">
                <h3 className="sub_title">10. GENERAL</h3>
                <ol>
                    <li><p>Assistance; Contact: If you have any issues with your use of the Platform, or issues with
                        your requests or purchases made through the Platform that you are unable to resolve
                        directly with the Third Party Vendor, our team will try to assist you where possible. You
                        can contact our team by selecting the “Need help?”, “Help” or similar button in the
                        Platform, or by calling the telephone number shown in the Platform. If you have any other
                        questions, complaints or claims about the Platform, or if you are having any technical
                        difficulties with the Platform, please email us at info@Jus-Pharma.com.</p></li>
                    <li><p>Severability: If any provision of these Terms is unlawful, void or unenforceable, that
                        provision is deemed severable from these Terms and does not affect the validity and
                        enforceability of any remaining provisions.</p></li>
                    <li><p>Applicable Law: These Terms and any disputes arising under or related to these Terms will
                        be governed by the laws of the State of New York, without reference to its conflict of law
                        principles.</p></li>
                    <li><p>Entire Agreement: These Terms constitute the entire, complete and exclusive agreement
                        between you and us regarding the Platform and supersede all prior agreements and
                        understandings with respect to their subject matter.</p></li>
                    <li><p>Waiver: The remedies of Jus-Pharma set forth in these Terms shall be cumulative and not
                        alternative, and the election of one remedy for a breach shall not preclude pursuit of other
                        remedies. Any failure by us, at any time or from time to time, to enforce any of our rights
                        under these Terms shall not constitute a waiver of such right.</p></li>
                    <li><p>Additional Terms Applicable to App Providers: The following applicable additional terms
                        and conditions are applicable with respect to your use of a third party to access and
                        download the Platform or any subsequent updates thereto, such as Apple, Inc.,
                        Amazon.com, Inc., Google, Inc., or any other similar third party “app store” or platform
                        (each an <b>“App Provider”</b>):</p>
                        <ul>
                            <li><p>You acknowledge and agree that (A) these Terms are binding between you and JusPharma only, and the App Providers are not a party to these Terms, and (B) as
                                between Jus-Pharma and such App Provider, it is Jus-Pharma that is responsible for
                                the App and the content thereof. You must use the App only on the applicable App
                                Provider-branded product. Your use of the App must comply with the terms of use
                                applicable to the App Provider source from which you obtain it. You acknowledge
                                that the App Provider has no obligation to furnish you with any maintenance and
                                support services with respect to the App.</p></li>
                            <li><p>You acknowledge that the App Provider is not responsible for addressing any claims
                                you have or any claims of any third party relating to the App or your possession and
                                use of the App, including, but not limited to (A) product warranty or liability claims;
                                (B) any claim that the App fails to conform to any applicable legal or regulatory
                                requirement; (C) claims arising under consumer protection or similar legislation; or
                                (D) claims that the App infringes a third party’s intellectual property rights, such that
                                you will not hold such App Provider responsible for the investigation, defense,
                                settlement and discharge of any such intellectual property infringement claims.</p></li>
                            <li><p>In the event of any failure of the App Provider version of the App to conform to any
                                applicable warranty that has not been effectively disclaimed by these Terms, you may
                                notify such App Provider, and such App Provider will refund the purchase price for
                                the App (if any) to you; to the maximum extent permitted by applicable law, such
                                App Provider will have no other warranty obligation whatsoever with respect to the
                                App, and, as between such App Provider and Jus-Pharma, any other claims, losses,
                                liabilities, damages, costs or expenses attributable to any failure to conform to any
                                warranty will be Jus-Pharma’s responsibility, but only to the extent provided by these
                                Terms. Please read the entire Terms, as other sections of these Terms limit JusPharma’s liability in this regard.</p></li>
                            <li><p>The App Providers are third-party beneficiaries of these Terms. Upon your
                                acceptance of the Terms, the applicable App Provider will have the right (and will be
                                deemed to have accepted the right) to enforce the Terms against you as a third party
                                beneficiary thereof. There are no other third-party beneficiaries of the Terms.</p></li>
                        </ul>    
                    </li>
                </ol>
            </div>

        </Container>
  </Layout>
)

export default TermsPage

